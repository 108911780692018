.trainingContainer {
    margin: 0 auto;
    max-width: 960px; /* Adjust maximum width as per your requirement */
  }
  
  .videoContainer {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%; /* 16:9 aspect ratio */
  }
  
  .videoContainer iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  @media (max-width: 550px) {
    .videoContainer {
      padding-top: 75%; /* Adjust aspect ratio for smaller screens */
    }
  }
  