.iconName{
    font-size: small;
    text-align: center;
    color:white;
    text-decoration: none;
}
.logout_btn{
    text-decoration: none;
}
.navbar .logo_cs{
    height: 60px;
    width: 60px;
    border-radius: 50px;
}
@media (max-width:570px){
    .iconName{
        font-size: 0.55rem;
        margin: 0;
        text-align: center;
    }

}