.applicationHeader {
  display: flex;

  height: 5em;
  width: 97vw;
}
.applicationHeader h3 {
  margin-top: 1.5em;
}
.detailsContailer {
  margin: 1em;
}
