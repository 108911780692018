.header{
    
    font-size: 1rem;
}
.logoName{
    margin-top: 0.5em;
    color: black;
}
.header img{
    
    margin-right: 0.5em;
    height: 60px;
    width: 60px;
    border-radius: 30px;
}
@media (max-width:620px){
    .logoName{
        font-size: 1.1rem;
    
    }
    .button{
        font-size: 1rem;
    }
    
  }