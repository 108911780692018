.items{
    display: flex;
    /* margin-bottom: 1em; */
}

.sideview {
    background: red;
  }

  
  @media (max-width: 550px) {
    .sideview {
      width: 70%;
      text-decoration: none;
    }
  }
  