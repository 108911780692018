.full {
  width: 100vw;
  height: 100vh;
  font-size: 1rem;

  text-align: center;
}

.full img {
  top: 80px;
  position: relative;
  width: 10em;
  border: 1px solid rgb(235, 235, 238);
  border-radius: 50%;
}
.full .card h3 {
  padding-top: 5em;
  margin-bottom: 3em;
}
.full .card {
  box-shadow: 5px 10px 15px grey;
  margin: auto;
  border-radius: 7px;
  height: 30em;
  width: 42em;
  background-color: azure;
}

.full .label {
  margin-right: 20em;
}
.fields {
  text-align: center;
}
.full .contact {
  margin-right: 23em;
}
.contactError {
  color: #f01f1f;
  font-size: 0.8rem;
}
