body {
   background-color: #635985;
}
.components{
   position:relative
}
.Navbar{
   position: fixed;
   top: 0;
}
.UserBody{
   position: absolute;
   min-height: 50vh;
   top: 50px;
   display: flex;
   flex-grow: 1;
   width: 100%;

}
.LeftSide{
   margin: 0;
   padding: 0;
   margin: -10px;
   flex-grow: 1; 
   position: fixed;
   min-height: 100vh;
   top: 80px;
   width: 25%;
   background-color: #11144C;
   color: white;
   font-weight: 300;
   
}
.RightSide{
   
   flex-grow: 1;
   position: absolute;
   min-height: 50vh;
   top: 50px;
   right: 2%;
   
   width: 70vw;
   

}
.button{
   text-transform: capitalize;
}

.linktag{
   color:white;
}
.LinkAnchorTag{
   color:white;
   text-decoration: none   ;
}

@media (max-width:901px){
   .LeftSide{
      display: none;
   }
   .RightSide{
      width: 100vw;
      width: 100%;
      min-height: 30vh;
   }
}

@media (max-width:768px){
   .LeftSide{
      display: none;
   }
   .RightSide{
      width: 100vw;
      min-height: 10vh;
   }
}

.logout_btn{
   display: "flex";
   color: white;
   text-decoration: "none";   
}



/* body {
   background-color: #635985;
}
.components{
   position:relative
}
.Navbar{
   position: fixed;
   top: 0;
}
.UserBody{
  
  margin-top: 70px;
   
   display: flex;
  
   width: 100%;

}
.LeftSide{
  
   flex: 6  ; 
  
  height: fit-content;
  
   width: 100%;
   background-color: #11144C;
   color: white;
   font-weight: 300;
   
}
.RightSide{
   margin-top: 20px;
   flex: 3;
   
   min-height: 50vh;
   overflow-y: scroll;

   width: 100%;
   

}
.button{
   text-transform: capitalize;
}

.linktag{
   color:white;
}
.LinkAnchorTag{
   color:white;
   text-decoration: none   ;
}

@media (max-width:412px){
   .LeftSide{
      display: none;
   }
   .RightSide{
      width: 100vw;
      width: 100%;
      min-height: 30vh;
   }
}

@media (max-width:768px){
   .LeftSide{
      display: none;
   }
   .RightSide{
      width: 100vw;
      min-height: 10vh;
   }
}

.logout_btn{
   display: "flex";
   color: white;
   text-decoration: "none";
   
} */