
.LinkAnchorTag {
    text-decoration: none;
}


@media (max-width: 768px) {
    .LeftSide {
        color: white;
        font-size: 15px;
    }

    .LeftSide:hover {
        background-color: aqua;
    }
}

@media (max-width: 550px) {
    .LeftSide {
        background-color: #11144C;
        color: white;
        font-size: 12px;
    }
    .LeftSide:hover {
        background-color: rgb(22, 190, 190);
    }
}
