  

  .MuiTableHead-root {
    background-color: #f0f0f0; 
  }
  

  .enable-disable-button {
    background-color: #007bff; 
    color: #fff;
    padding: 10px; 
    border: none; 
    cursor: pointer; 
  }
  
 
  .access-granted {
    color: white;
    padding: 8px;
    background-color: green; /* Green background color for access granted */
  }
  
  /* Access Denied Styles */
  .access-denied {
    color: white;
    padding: 8px;
    background-color: red; /* Red background color for access denied */
  }
  
@media(max-width: 600px) {
  
  body {
    background-color: lightblue;
  }
   .container {
    width: 90%;
  }
}


@media(min-width: 1200px) {
 
  body {
    background-color: lightgray;
  }
  .container {
    width: 80%;
  }
}
