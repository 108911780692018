.profileCard{
    width: 100%;
    background-color: #ffffff;
    border-radius: 7px;
    margin: 1em 0;
}
.cardContent{
    
    padding-left: 2em;
    padding-bottom: 2em;
}