.whole_container {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  background-color: white;
  max-width: 1000px;
}


  .abstract_container {
    position: relative;
    overflow: hidden;
  }

  
  .certificate_container {
    text-align: left;
    padding: 40px;
    line-height: 3rem;
  }

  .stars {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .star {
    font-size: 20px;
  }

  .yellow {
    color: #ffd700;
  }

  .blue {
    color: #4a90e2;
  }

  .certificate_title {
    font-size: 5rem;
    font-weight: 300;
    margin: 0 !important;
  }

  .certificate_subtitle {
    font-size: 18px;
    font-weight: 500;
    color: gray;
  }

  .line {
    width: 10rem;
    height: 5px;
    background-color: #ffa07a;
    margin-bottom: 3rem;
  }

  .handwritten_line {
    width: 10rem;
    height: 5px;
    background: linear-gradient(
      to left,
      #000 5%,
      rgba(255, 160, 122, 0) 100%
    );
    border-radius: 50px;
    margin-bottom: 3rem;
    transform: scaleX(-1);
  }

  .certificate_text {
    font-size: 16px;
    font-weight: 500;
    color: gray;
    margin: 10px 0;
  }

  .name {
    font-size: 32px;
    font-weight: bold;
    font-family: "Georgia", serif;
    margin: 10px 0;
  }

  .certificate_detail {
    font-size: 14px;
    line-height: 1.6;
    margin: 10px 0;
  }

  .course_title {
    font-weight: bold;
    color: #d24d57;
  }

  .date {
    font-size: 14px;
    margin-top: 20px;
    color: gray;
  }

  .valid {
    position: relative;
    top: 580px;
    left: 110px;
    max-width: 23rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
  }