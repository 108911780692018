body{
    background-color:#E7F0FD;
    font-family: "Merriweather", serif;
}
.logo img {
  width: 9%;
  
  padding: 1em;
  margin-left: 0;
  border-radius: 50px;
}
.logo{
    text-align: center;
    margin-bottom: 2.5em;
}
.title{
    text-align: center;
}
.users{
    display: flex;
    justify-content: center;
   
    
}
.userCards{
    margin: 2em;
    
    
   
}
@media (max-width:618px){
    
    .users{
        padding: 1em;
        
    }
}

