.header {
  text-align: center;
  height: 100vh;
  width: 30vw;
   margin: auto;
}
.header h2 {
  margin-top: 5em;
  font-size: 1.8rem;
  margin-bottom: 0.3em;
}
.notVerified{
    color: red;
}
.header h4{
    font-weight: 500;
    font-size: 0.8em;
}
